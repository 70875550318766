import React from "react";
import ClipPathRight from "../utils/ClipPathRight";
import ClipPathLeft from "../utils/ClipPathLeft";

const Image = ({source, clipPath, ratio}) => {
    if(source) {
        // const formats = source.formats;
        let base_url = 'https://res.cloudinary.com/new-territory-delta/image/upload';
        let src = source.provider_metadata.public_id;
        return (
            <div className={`image-container ratio-${ratio}`} data-clip-path={clipPath}>
                {clipPath === 'left' ? 
                    <ClipPathLeft />
                : clipPath === 'right' ?
                    <ClipPathRight />
                : null }
                {/* <img 
                    className="content"
                    src={`${formats.thumbnail.url}`}
                    srcset=
                    {`${formats.small.url} 280w, 
                    ${formats.medium.url} 440w`}
                    sizes=
                    {`(max-width: 320px) 280px, 
                    (max-width: 480px) 440px, 
                    800px`}
                /> */}
                <img 
                src={`${base_url}/${src}`}
                className="content"
                alt="" 
                srcSet=
                {`
                ${base_url}/w_280/${src} 280w,
                ${base_url}/w_400/${src} 400w,
                ${base_url}/w_1800/${src} 1800w
                `}
                loading="lazy"
                sizes=
                {`(max-width: 320px) 280px, 
                (max-width: 480px) 440px, 
                (max-width: 1800px) 1800px
                800px`}
                />
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}

export default Image;